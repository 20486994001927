import React, { Fragment, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames/bind'
import { animated, useTrail } from 'react-spring'
import { useRafLoop } from 'react-use'

import { ButtonText } from 'components/ui/Text'
import Clickable from 'components/ui/Clickable'
import ViewportEnter from 'components/core/ViewportEnter'
import checkHoverSupport from 'lib/checkHoverSupport'

import { TrailButtonAnimationType, TrailButtonType } from './TrailButtonTypes'
import * as s from './TrailButton.module.scss'

const cn = classNames.bind(s)

const getAnimationProps = ({
  mouse,
  rotation,
  isInitialState = false,
  noDuration = false,
}: TrailButtonAnimationType) => {
  return {
    opacity: isInitialState ? 0 : 1,
    transform: `
        translate(-50%, -50%)
        translate3d(${mouse.x}px, ${mouse.y}px, 0px)
        scale(${isInitialState ? 0.6 : 1})
        rotate(${rotation.current}deg)
      `,
    config: noDuration ? { duration: 0 } : { tension: 3000, friction: 150 },
  }
}

const TrailButton = ({
  text,
  background,
  invertedText,
  isMouseOver,
  mouse,
  url = '',
  mobileClass = '',
  mobileAnimationDelay = 0,
  mobileMargin = true,
}: TrailButtonType) => {
  const [hasHoverSupport, setHasHoverSupport] = useState(false)
  const [isInView, setIsInView] = useState(false)

  const rotation = useRef(0)
  const mouseXHistory = useRef<number[]>([]).current

  const rectanglesCount = 4
  const rotationSpeed = 0.4

  useEffect(() => {
    setHasHoverSupport(checkHoverSupport())
  }, [])

  const [trail, api] = useTrail(rectanglesCount, () =>
    getAnimationProps({ mouse, rotation, isInitialState: true, noDuration: true }),
  )

  useEffect(() => {
    api.start(getAnimationProps({ mouse, rotation, isInitialState: true, noDuration: !!isMouseOver }))
  }, [isMouseOver, api, mouse])

  useRafLoop(() => {
    if (!hasHoverSupport || !isMouseOver) return

    mouseXHistory.push(mouse.x)

    if (mouseXHistory.length >= 2) {
      if (mouseXHistory.length >= 3) {
        mouseXHistory.shift()
      }

      const distance = mouseXHistory[0] - mouseXHistory[1]

      rotation.current = Math.ceil(rotationSpeed * -distance)
    }

    api.start(getAnimationProps({ mouse, rotation }))
  })

  return (
    <>
      {!hasHoverSupport && (
        <ViewportEnter onEnter={() => setIsInView(true)}>
          <div className={cn('buttonWrapperMobile', mobileClass, { isInView, mobileMargin })}>
            <Clickable
              to={url}
              className={cn('buttonMobile', { invertedText })}
              style={{ background, transitionDelay: `${mobileAnimationDelay}s` }}
            >
              <ButtonText as='span' className={cn('buttonTextMobile')}>
                {text}
              </ButtonText>
            </Clickable>

            <animated.div
              className={cn('buttonTrailMobile')}
              style={{ transitionDelay: `${mobileAnimationDelay + 0.15}s` }}
            />
            <animated.div
              className={cn('buttonTrailMobile')}
              style={{ transitionDelay: `${mobileAnimationDelay + 0.3}s` }}
            />
          </div>
        </ViewportEnter>
      )}

      {typeof window !== 'undefined' &&
        hasHoverSupport &&
        createPortal(
          <div className={cn('wrapper')}>
            {trail.reverse().map(({ ...style }, index) => (
              <Fragment key={index}>
                {index === rectanglesCount - 1 ? (
                  <animated.div style={style} className={cn('buttonWrapper')}>
                    <Clickable to={url} className={cn('button', { invertedText })} style={{ background }}>
                      <ButtonText as='span'>{text}</ButtonText>
                    </Clickable>
                  </animated.div>
                ) : (
                  <animated.div style={style} className={cn('buttonTrail')} />
                )}
              </Fragment>
            ))}
          </div>,
          document.body,
        )}
    </>
  )
}

export default TrailButton
