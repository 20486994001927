import React from 'react'
import { Link } from 'gatsby'

type AnchorProps = {
  to: string
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

type ClickableProps = ({ to: undefined } & ButtonProps) | AnchorProps

const externalProps = { target: '_blank', rel: 'noopener noreferrer' }

const Anchor = ({ to, className, style, children, target, onClick, ...p }: AnchorProps) => {
  const props = { className, style, target, onClick }

  // internal links
  if (/^[.#/]/.test(to))
    return (
      <Link {...props} to={to}>
        {children}
      </Link>
    )

  // external links
  if (/^https?:/.test(to))
    return (
      <a {...p} {...props} href={to} {...externalProps}>
        {children}
      </a>
    )

  // fallback for anything strange
  return (
    <a {...p} {...props} href={to}>
      {children}
    </a>
  )
}

const Clickable = ({ to, ...props }: ClickableProps) => {
  if (!to) return <button {...(props as ButtonProps)} />

  return <Anchor {...(props as AnchorProps)} to={to} />
}

export default Clickable
