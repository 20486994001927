// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var wrapper = "TrailButton-module--wrapper--kKvOG";
export var buttonWrapper = "TrailButton-module--buttonWrapper--pyD81";
export var buttonTrail = "TrailButton-module--buttonTrail--4PK1y";
export var buttonTrailMobile = "TrailButton-module--buttonTrailMobile--lS8Vw";
export var button = "TrailButton-module--button--Nq7Dj";
export var buttonMobile = "TrailButton-module--buttonMobile--LTC+j";
export var invertedText = "TrailButton-module--invertedText--wecGr";
export var buttonWrapperMobile = "TrailButton-module--buttonWrapperMobile--ypdle";
export var mobileMargin = "TrailButton-module--mobileMargin--BEHg5";
export var isInView = "TrailButton-module--isInView--W7FqE";
export var buttonTextMobile = "TrailButton-module--buttonTextMobile--slGx+";