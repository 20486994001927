import React, { memo } from 'react'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'
import { CaptionText } from 'components/ui/Text'

import * as s from './LoaderUI.module.scss'

const cn = classNames.bind(s)

const LoaderUI = () => {
  const loadProgress = useUIContext(s => s.loadProgress)
  const isLoaded = useUIContext(s => s.isLoaded)
  const setIsLoaderAnimated = useUIContext(s => s.setIsLoaderAnimated)

  return (
    <div className={cn('loaderUI', { isIdle: !loadProgress, isLoaded: loadProgress === 100 || isLoaded })}>
      <CaptionText className={cn('loaderLabel')}>{`${!isLoaded ? loadProgress || 0 : 100}%`}</CaptionText>
      <CaptionText className={cn('loadedLabel')}>Extend Your Reality</CaptionText>
      <div className={cn('loadedLine')} onAnimationEnd={() => setIsLoaderAnimated(true)} />
    </div>
  )
}

export default memo(LoaderUI)
