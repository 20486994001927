import React from 'react'
import classNames from 'classnames/bind'

import Marquee from 'components/ui/Marquee'
import LoaderUI from 'components/ui/LoaderUI'
import { HeroText } from 'components/ui/Text'
import Line from 'components/ui/Line'

import * as s from './Tagline.module.scss'
const cn = classNames.bind(s)

type Props = {
  tagline: string
  marqueeSpeed?: number | undefined
  renderLoaderUI?: boolean
}

const Tagline = ({ tagline, marqueeSpeed, renderLoaderUI = true }: Props) => {
  return (
    <div className={cn('tagline')}>
      <Marquee speed={marqueeSpeed}>
        <HeroText>{tagline}</HeroText>
      </Marquee>
      <Line position='bottom' duration={1.2} delay={0.4} />
      {renderLoaderUI && <LoaderUI />}
    </div>
  )
}

export default Tagline
