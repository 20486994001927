// extracted by mini-css-extract-plugin
export var colorBackground = "#151515";
export var colorForeground = "#fff";
export var colorGradient1Start = "#f969fc";
export var colorGradient1Mid = "#bc01f0";
export var colorGradient1End = "#0c01de";
export var colorCaption = "#e5e4e5";
export var colorGlitchGreen = "#55ff7a";
export var colorGlitchPurple = "#bc01f0";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1000px";
export var breakpointLarge = "1400px";
export var loadedLine = "LoaderUI-module--loadedLine--G5XCM";
export var loaderUI = "LoaderUI-module--loaderUI--xkn1j";
export var fadeIn = "LoaderUI-module--fadeIn--dOJ1K";
export var loaderLineProgress = "LoaderUI-module--loaderLineProgress--AI2i1";
export var isLoaded = "LoaderUI-module--isLoaded--SX7rB";
export var loaderLineLoaded = "LoaderUI-module--loaderLineLoaded--x2IkT";
export var loaderLabel = "LoaderUI-module--loaderLabel---lKM7";
export var isIdle = "LoaderUI-module--isIdle--O469-";
export var blink = "LoaderUI-module--blink--xxvzw";
export var loadedLabel = "LoaderUI-module--loadedLabel--Kz+OR";
export var loaderLineRemove = "LoaderUI-module--loaderLineRemove--tGSr7";